.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Use the full height of the viewport */
  position: relative; /* Needed for absolute positioning of children */
  margin: 0;
  padding: 0;
  transition: all 0.5s ease;
  background: url('church.jpg') no-repeat center center fixed; 
  background-size: cover;
  overflow: hidden;
}

/* Adding an overlay with a dark, semi-transparent background */
.App::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay */
  backdrop-filter: blur(5px); /* Blurring the background image */
}

.content {
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
  width: 100%;
  z-index: 1; /* Ensure content is above the overlay */
}

.content-hidden {
  transform: translateY(-50px);
  opacity: 0;
  pointer-events: none;
}

.textarea {
  padding: 10px;
  font-size: 16px;
  border: 2px solid rgba(221, 221, 221, 0.5); /* Semi-transparent border */
  border-radius: 5px;
  width: 300px;
  max-width: 80%;
  resize: none;
  overflow: hidden;
  transition: height 0.5s ease, margin-bottom 0.5s ease;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  color: rgba(0, 0, 0, 0.7); /* Darker text for better readability */
  outline: none;
}

.textarea:focus {
  box-shadow: 0 0 0 2px rgba(111, 0, 0, 1);
}

.submit-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(111, 0, 0, 0.7); /* Semi-transparent blue */
  color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, opacity 0.5s ease, visibility 0.5s ease;
}

.button-hidden {
  opacity: 0;
  visibility: hidden;
}

.verse-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: transform 0.5s ease, opacity 0.5s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  z-index: 1; /* Ensure content is above the overlay */
}

.verse {
  font-size: 24px;
  line-height: 1.6;
  color: rgba(85, 85, 85, 0.9); /* Semi-transparent text */
  margin: 20px auto;
  max-width: 600px;
  text-align: center;
  font-style: italic;
  transition: opacity 0.5s ease;
  background-color: rgba(255, 255, 255, 0.5); /* Semi-transparent background */
  padding: 20px; /* Add padding to give some space inside the container */
  border-radius: 5px; /* Optional: rounded corners for the verse container */
}

.go-back-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.3); /* Semi-transparent white */
  color: rgba(111, 0, 0, 0.7); /* Semi-transparent blue */
  border: 2px solid rgba(111, 0, 0, 0.7); /* Semi-transparent blue border */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.go-back-button:hover {
  background-color: rgba(111, 0, 0, 0.7); /* Darker semi-transparent blue */
  color: rgba(255, 255, 255, 0.9); /* Slightly transparent white */
}

.input-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  z-index: 1; /* Ensure content is above the overlay */
}

/* Mobile styles */
@media (max-width: 768px) {
  .textarea {
    width: 90%; /* Increase width to use more of the screen */
    font-size: 14px; /* Smaller font size */
  }

  .submit-button {
    padding: 8px 16px;
    font-size: 14px; /* Smaller font size */
  }

  .verse {
    font-size: 18px; /* Smaller font size */
    padding: 15px; /* Less padding to save space */
    margin: 10px auto; /* Less margin to save space */
  }

  .go-back-button {
    padding: 8px 16px;
    font-size: 14px; /* Smaller font size */
  }
}

/* You can add even more specific queries for smaller devices */
@media (max-width: 480px) {
  .textarea {
    font-size: 12px; /* Even smaller font size for very small devices */
  }

  .verse {
    font-size: 16px; /* Even smaller font size for very small devices */
  }

  .go-back-button {
    font-size: 12px; /* Even smaller font size for very small devices */
  }
}
